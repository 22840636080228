<template>
  <RaiSetups v-model="activeSetup" :loading="loading">
    <ConnectAccountSetup
      :automation="automation"
      :accounts="accounts"
      :type="completedSetups[0]"
      @add:account="onAccountAdd"
      @select:account="onAccountSelect"
      @update:automation="onAutomationUpdate"
      @sync:contact-list="onContactListSync"
      @next="onNext"
    />
  </RaiSetups>
</template>

<script>
import { subscribeOneOff } from "@/utils/graphql";
import { mapState } from "vuex";
import { RaiSetups, SetupType } from "@/core-ui";
import { useAccountView } from "./mixins/useAccountView";
import { ConnectAccountSetup } from "./components/ConstantContact";
import { hasSnackbarAccess } from "@/mixins/snackbar";

import {
  CONSTANT_CONTACT_CONFIG_QUERY,
  CONSTANT_CONTACT_ACCOUNTS_SELECT_QUERY,
  CONSTANT_CONTACT_AUTOMATION_QUERY,
  CONSTANT_CONTACT_AUTOMATION_UPDATE,
  CONSTANT_CONTACT_LISTS_SYNC,
  CONSTANT_CONTACT_LISTS_SYNCED_SUBSCRIPTION,
  CONSTANT_CONTACT_ACCOUNT_ADD_SUBSCRIPTION,
  CONSTANT_CONTACT_ACCOUNT_SYNCED_SUBSCRIPTION,
} from "./graphql/ConstantContact";

export default {
  name: "ConstantContactView",
  components: { RaiSetups, ConnectAccountSetup },
  mixins: [
    hasSnackbarAccess,
    useAccountView({
      prop: "constantContactAccounts",
      query: CONSTANT_CONTACT_ACCOUNTS_SELECT_QUERY,
    }),
  ],
  data: (vm) => ({
    activeSetup: 0,
    loading: false,
    automation: vm.buildDefaultAutomation(),
    completedSetups: new Array(1),
  }),
  apollo: {
    constantContactConfig: {
      query: CONSTANT_CONTACT_CONFIG_QUERY,
      update(response) {
        return response.constantContactConfig;
      },
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      skip() {
        return !this.storeId;
      },
    },
    automation: {
      query: CONSTANT_CONTACT_AUTOMATION_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
      update(response) {
        return (
          response.constantContactAutomation || this.buildDefaultAutomation()
        );
      },
      skip() {
        return !this.storeId;
      },
      watchLoading(isLoading) {
        this.setLoading(isLoading);
      },
    },
    $subscribe: {
      constantContactAccountAdded: {
        query: CONSTANT_CONTACT_ACCOUNT_ADD_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
      constantContactAccountSynced: {
        query: CONSTANT_CONTACT_ACCOUNT_SYNCED_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
      constantContactAccountListsSynced: {
        query: CONSTANT_CONTACT_LISTS_SYNCED_SUBSCRIPTION,
        variables() {
          return {
            storeId: this.storeId,
            integrationConstantContactId:
              this.automation?.integrationConstantContactId,
          };
        },
        skip() {
          return !this.storeId || !this.hasConnectedAccount;
        },
      },
    },
  },
  computed: {
    ...mapState("auth", { userId: "user_id" }),
    hasConnectedAccount() {
      return this.automation?.integrationConstantContactId > 0;
    },
  },
  methods: {
    buildDefaultAutomation() {
      return {
        integrationConstantContactId: null,
        constantContactListId: null,
      };
    },
    setLoading(value) {
      this.loading = value;
    },
    onNext() {
      this.completedSetups[this.activeSetup] = SetupType.COMPLETE;
    },
    onAccountAdd(context) {
      this.subscribeToAccountAdded(context);
      this.subscribeToAccountSynced(context);

      window.open(
        this.constantContactConfig.authorizationUrl,
        "ccAuthWindow",
        "toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1,width=800,height=600"
      );
    },
    onAccountSelect(values) {
      if (!this.automation) {
        this.automation = {};
      }
      this.automation.integrationConstantContactId =
        values.integrationConstantContactId;
    },
    subscribeToAccountAdded(context) {
      subscribeOneOff(this, {
        name: "constantContactAccountAdded",
        resolve: (result) => {
          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.constantContact.accountAddedSuccessText"
                )
              : this.$t(
                  "settings.automation.constantContact.accountAddedErrorText",
                  { error: result.errors.join(", ") }
                ),
          });

          if (!result.success) {
            context.resolve();
          }
        },
      });
    },
    subscribeToAccountSynced(context) {
      subscribeOneOff(this, {
        name: "constantContactAccountSynced",
        resolve: async (result) => {
          context.resolve();

          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.constantContact.accountSyncedSuccessText"
                )
              : this.$t(
                  "settings.automation.constantContact.accountSyncedErrorText",
                  { error: result.errors.join(", ") }
                ),
          });

          await this.$apollo.queries.accountList.refetch();
        },
      });
    },
    async onAutomationUpdate({ values, resolve }) {
      const { data } = await this.$apollo.mutate({
        mutation: CONSTANT_CONTACT_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: values,
        },
      });

      if (data.errors?.length > 0) {
        console.error("ERROR: ConstantContact Automation Update");
        return;
      }

      this.automation = values;
      resolve();
    },
    async onContactListSync(context) {
      this.subscribeToListsSynced(context);

      await this.$apollo.mutate({
        mutation: CONSTANT_CONTACT_LISTS_SYNC,
        variables: {
          storeId: this.storeId,
          integrationConstantContactId:
            context.values.integrationConstantContactId,
        },
      });
    },
    subscribeToListsSynced(context) {
      subscribeOneOff(this, {
        name: "constantContactAccountListsSynced",
        resolve: async (result) => {
          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.constantContact.listsSyncedSuccessText"
                )
              : this.$t(
                  "settings.automation.constantContact.listsSyncedErrorText",
                  { error: result.errors.join(", ") }
                ),
          });

          this.$apollo.queries.accountList.refetch();

          context.resolve();
        },
      });
    },
  },
};
</script>
