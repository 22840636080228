var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AccountSetup',_vm._g(_vm._b({attrs:{"accounts":_vm.accounts},on:{"change:account":_vm.onAccountChange,"submit":_vm.updateAutomation},scopedSlots:_vm._u([{key:"account",fn:function({ attrs, on }){return [_c('AccountSelect',_vm._g(_vm._b({attrs:{"loadable":"","name":"integrationConstantContactId"},model:{value:(_vm.form.integrationConstantContactId),callback:function ($$v) {_vm.$set(_vm.form, "integrationConstantContactId", $$v)},expression:"form.integrationConstantContactId"}},'AccountSelect',attrs,false),on))]}}])},'AccountSetup',_vm.$attrs,false),_vm.$listeners),[(_vm.form.integrationConstantContactId > 0)?_c('VxFormGroup',{attrs:{"title":_vm.$t(
        'settings.automation.constantContact.connectAccount.contactList.groupTitle'
      )}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.constantContact.connectAccount.contactList.description" ))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('VxSelect',{attrs:{"loading":_vm.syncingLists,"no-data-text":_vm.$t(
              'settings.automation.constantContact.connectAccount.contactList.select.noDataText'
            ),"name":"constantContactListId","label":_vm.$t(
              'settings.automation.constantContact.connectAccount.contactList.select.label'
            ),"placeholder":_vm.$t(
              'settings.automation.constantContact.connectAccount.contactList.select.placeholder'
            ),"rules":"required","items":_vm.selectedAccountContactLists},model:{value:(_vm.form.constantContactListId),callback:function ($$v) {_vm.$set(_vm.form, "constantContactListId", $$v)},expression:"form.constantContactListId"}})],1)],1),_c('p',{staticClass:"text--secondary text-caption"},[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.constantContact.connectAccount.contactList.syncText" ))+" "),_c('RaiLink',{staticClass:"text-caption",attrs:{"disabled":_vm.syncingLists},on:{"click":_vm.syncContactList}},[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.constantContact.connectAccount.contactList.syncBtnText" ))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }