<template>
  <AccountSetup
    :accounts="accounts"
    v-bind="$attrs"
    @change:account="onAccountChange"
    @submit="updateAutomation"
    v-on="$listeners"
  >
    <template #account="{ attrs, on }">
      <AccountSelect
        v-model="form.integrationConstantContactId"
        loadable
        name="integrationConstantContactId"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <VxFormGroup
      v-if="form.integrationConstantContactId > 0"
      :title="
        $t(
          'settings.automation.constantContact.connectAccount.contactList.groupTitle'
        )
      "
    >
      <p>
        {{
          $t(
            "settings.automation.constantContact.connectAccount.contactList.description"
          )
        }}
      </p>
      <v-row>
        <v-col cols="12" md="6">
          <VxSelect
            v-model="form.constantContactListId"
            :loading="syncingLists"
            :no-data-text="
              $t(
                'settings.automation.constantContact.connectAccount.contactList.select.noDataText'
              )
            "
            name="constantContactListId"
            :label="
              $t(
                'settings.automation.constantContact.connectAccount.contactList.select.label'
              )
            "
            :placeholder="
              $t(
                'settings.automation.constantContact.connectAccount.contactList.select.placeholder'
              )
            "
            rules="required"
            :items="selectedAccountContactLists"
          />
        </v-col>
      </v-row>
      <p class="text--secondary text-caption">
        {{
          $t(
            "settings.automation.constantContact.connectAccount.contactList.syncText"
          )
        }}
        <RaiLink
          :disabled="syncingLists"
          class="text-caption"
          @click="syncContactList"
        >
          {{
            $t(
              "settings.automation.constantContact.connectAccount.contactList.syncBtnText"
            )
          }}
        </RaiLink>
      </p>
    </VxFormGroup>
  </AccountSetup>
</template>

<script>
import AccountSetup from "../AccountSetup/AccountSetup.vue";
import AccountSelect from "../AccountSelect/AccountSelect.vue";
import { VxFormGroup, VxSelect, RaiLink } from "@/core-ui";
import { useFormObject } from "@/mixins/useFormObject";

export default {
  name: "ConnectAccountSetup",
  components: { AccountSetup, AccountSelect, VxFormGroup, VxSelect, RaiLink },
  mixins: [
    useFormObject({
      prop: "automation",
      default: {
        integrationConstantContactId: null,
        constantContactListId: null,
      },
    }),
  ],
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    syncingLists: false,
  }),
  computed: {
    selectedAccountContactLists() {
      const selectedAccount = this.accounts.find(
        (acc) => acc.value === this.form.integrationConstantContactId
      );
      return selectedAccount?.contactLists || [];
    },
  },
  methods: {
    onAccountChange() {
      this.form.constantContactListId = null;
      this.$emit("select:account", { ...this.form });
    },
    syncContactList() {
      this.syncingLists = true;
      this.$emit("sync:contact-list", {
        values: { ...this.form },
        resolve: () => {
          this.syncingLists = false;
        },
      });
    },
    updateAutomation(formContext) {
      this.$emit("update:automation", formContext);
    },
  },
};
</script>
